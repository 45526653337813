import {EducationLevelTypes, ProfileCompletion, StudentCategory} from '../../shared/shared.constants';
import {IDocumentForm} from '../steps/documentation-step/documentation-step.constants';
import {TrackerEventDeprecated} from '../../shared/tracker.constants';
import {FormNames} from './static/documentation-form';

export enum USER_TEST_TYPE {
  A = 0,
  B = 1,
  C = 2,
}

export interface IQuestion {
  id: number;
  options: string[];
  question: string;
  images: string;
  question_category: any;
}

export type IProgressItemClasses = 'completed' | 'ongoing' | 'disabled' | 'failed';

export interface IPollQuestion {
  id: number;
  poll_question: string;
  poll_options: string[];
  is_multi: '0' | '1';
  poll_options_images?: string;
  trackerEvent?: TrackerEventDeprecated;
}

export const counselorMessages = {
  welcomeToInteraction: `Welcome to recorded interview stage. Please watch the video below`,
  aboutTest: (name: any) => `Hi ${name}, you can start or schedule your test to start your Sunstone journey`,
  needDocuments: `Upload your documents and our team will get them verified within 2 working days. Sit tight!`,
  documentsSubmitted: `Thank you for being patient with us! Your documents will be verified within 48 hours.`,
  documentsVerified: `Thank you for being patient with us! Your documents will be verified within 48 hours.`,
  documentsRejected: `Your documents have been rejected. Don't worry, call us and we'll help you out!`,
  applicationWaitingForOffer: `Great! You have been selected. Sit tight, while we roll out your offer letter. Reach out to me for any queries.`,
  payRegistration: `We have generated your offer letter. To book your seat, pay registration fee amount.`,

  applicationRejected: `Your application has been rejected. Don't worry, call us and we'll help you out!`,
  requestRefund: 'Your refund has been requested. If this is a mistake, please reach out to me ',
  refundMessage: (name: any) =>
    `Hey ${name}, your seat has been revoked and refund has been processed against your application. If this is a mistake, please reach out to me urgently.`,
  seatBooked: (name: any, program: any, campus: any) =>
    `Congratulations ${name}! Your seat has been booked for ${program} at ${campus}. Please reach out to me for any queries. `,
  notEligible: 'Unfortunately, we were not able to find any suitable program for you.',
};

export const HELPLINE_NUMBER = '+917065303030';

export interface IProgramItem {
  id: number;
  name: string;
  medium_text: string;
  medium_text_color?: string;
  medium_background_color?: string;
  fomo?: string;
  fomo_text_color?: string;
  fomo_background_color?: string;
  affiliated_by?: string;
  offered_by: string[];
  duration?: number;
  details_link?: string;
  canApply?: 1 | 0;
  message?: string;
}

export interface ICampusData {
  id: number;
  image: string;
  name: string;
  subheading: string;
  amenities: string;
  fomo: string;
  fomo_background_color: string;
  fomo_text_color: string;
  affiliated_by: string;
  location: string;
  details_link: string;
}

export interface ICounsellorData {
  name: string;
  email: string;
  mobile: string;
  whatsapp_no?: any;
  profile_picture?: any;
  about_counsellor?: any;
  reporting_to: number;
  gender?: 'Male' | 'Female' | null;
}

interface IFomoItem {
  fomo: string;
  fomo_level: string;
}

export interface IProgramData {
  id: number;
  name: string;
  hub_name: string;
  short_name: string;
  category: string;
  campus?: any;
  specialisation?: any;
  show_status: string;
  eligibility?: any;
  gross_factor: number;
  fee_amount: number;
  program_data: IFomoItem;
  group: string;
  duration: number;
  allocation_model: string;
  program_type: string;
  program_manager_id: number;
  status: string;
  created: Date;
  modified: Date;
}

export enum ILeadDataReqStatus {
  inactive = '0',
  active = '1',
  accepted = '2',
  rejected = '3',
  reUploaded = '4',
}

export interface ILeadDataReq {
  id: number;
  user_id: number;
  counsellor_id?: any;
  key: string;
  value: string;
  doc_url: string;
  reviewed_by?: any;
  reviewed_datetime?: any;
  status: ILeadDataReqStatus;
  created: Date;
  modified: Date;
  show_key: string;
}

export interface ICampusData {
  campus_fullname?: string;
  campus_city?: string;
}

export interface IUserData {
  start_onboarding?: any;
  campus_data?: ICampusData;
  id: number;
  name?: any;
  sunstone_student_id?: any;
  sunstone_email?: any;
  email?: any;
  mobile?: string;
  program?: any;
  login_otp?: string;
  login_otp_time?: Date;
  session_key?: string;
  whatsapp_no?: any;
  is_whatsapp_verified?: string;
  is_email_verified?: string;
  role?: string;
  gender?: any;
  pincode?: any;
  city?: any;
  state?: any;
  country?: number;
  campus_id?: any;
  address?: any;
  aadhar_no?: any;
  status?: string;
  profile_completion_status: ProfileCompletion;
  projected_admission_year?: string;
  counseller_id?: number;
  counsellorNumber?: string;
  npf_user_id?: any;
  haptik_flag?: any;
  created?: Date;
  how_came_to_know?: any;
  how_came_to_know_sub?: any;
  student_documents?: any;
  user_type?: string;
  ask_guardian_detail?: string;
  sunstone_agreement?: string;
  student_onboarding?: any;
  profile_percentage?: number;
  counseller_data?: ICounsellorData;
  lead_data_requests?: ILeadDataReq[];
  program_data?: IProgramData;
  user_test_type?: number;
  pending_change_campus_request?: boolean;
  interview_scheduled?: boolean;
  interview_url?: any;
  eligibility_check_date?: string;
  offer_letter_version?: 'V1' | 'V2';
  // student_data fields
  graduation_year?: any;
  graduation_degree?: any;
  graduation_percentage?: any;
  gradutaion_gpa?: number;
  graduation_gpa_max?: number;
  graduation_college?: any;
  management_exam_name?: any;
  management_exam_score?: any;
  x_year?: any;
  x_percentage?: any;
  x_gpa?: any;
  x_gpa_max?: number;
  xii_year?: any;
  xii_percentage?: any;
  xii_gpa?: any;
  xii_gpa_max?: number;
  family_income?: any;
  work_experience?: any;
  gaurdian_name?: any;
  gaurdian_no?: any;
  parent_no?: string;
  parent_relation?: string;
  guardian_relationship?: any;
  category?: StudentCategory;
  schedule_test_time?: any;
  schedule_interview_time?: any;
  se_test_score?: any;
  se_test_max_score?: any;
  payment_program?: any;
  first_installment_amount?: any;
  offerletter_deadline?: any;
  campus_requested?: any;
  campus_requested_name?: any;
  highest_qualification?: EducationLevelTypes;
  lead_journey?: ProfileCompletion[];
  videoInterview?: any;
  skipPiStage?: boolean;
  version_code?: number;
  leadDocuments?: UploadedDocsList[];
  admission_end_year?: string;
}

export interface UploadedDocsList {
  id?: number;
  key?: string;
  doc_url?: string;
  status?: string;
  show_key?: string;
}

export enum AdmissionFormStages {
  highestQualification = 1.1,
  educationDetails = 1.2,
  personalDetails = 1.3,
  campusProgramDetails = 1.4,
  baseLeadDetails = 1,
  profileData = 1.5,
  documentStage = 5,
}

export enum TestSteps {
  TEST_INFO,
  TEST_SCHEDULE,
  TEST_READY,
}

export const EligibilityProcessSteps = {
  EDUCATION_SELECT: 'Education Select',
  EDUCATION_DETAILS: 'Education details',
  APPLICATION: 'Personal Details',
  GREAT_WORK: 'Great Work',
  CHECKING_ELIGIBILITY: 'Checking Eligibility',
  ELIGIBILITY_END: 'ELIGIBILITY_END', // so that we can know when to dismiss popover
};

export const CampusSelectSteps = {
  PROGRAM_SELECT: 'Program Select',
  // LOCATION_SELECT: 'Location Select',
  CAMPUS_SELECT: 'Campus Select',
  PAYMENT_SELECT: 'Payment',
  CAMPUS_SELECT_END: 'CAMPUS_SELECT_END', // so that we can know when to dismiss popover
};

export enum MasterProfileStep {
  MAIN,
  LOCATION_CITY,
  LOCATION_STATE,
  FORM_AADHAAR,
  FORM_DOC,
}

export type QuestionType = 'multi_select' | 'document_upload';

export interface IQuestion {
  id: number;
  options: string[];
  question: string;
  images: string;
  question_category: any;
  max_allowed_response: number;
  type: QuestionType;
  score?: number;
  status?: string;
  category?: {};
}

export interface ISection {
  id: number;
  title: string;
  total_questions: number;
  questions: IQuestion[];
  previousSectionsQuestionCount: number;
}

export interface IPollQuestion {
  id: number;
  poll_question: string;
  poll_options: string[];
  is_multi: '0' | '1';
}

export enum SUNSTONE_TEST_VARIANT {
  SEAT = 1,
  SCHOLARSHIP_TEST = 2,
  SEAT_ALTERNATE_VERSION = 3,
  SEAT_ALTERNATE_VERSION_D = 4,
  SEAT_ALTERNATE_VERSION_E = 5,
  SEAT_ALTERNATE_VERSION_F = 6,
}

export const SUNSTONE_TEST_VARIANT_NAME: Record<SUNSTONE_TEST_VARIANT, string> = {
  [SUNSTONE_TEST_VARIANT.SEAT]: '1',
  [SUNSTONE_TEST_VARIANT.SCHOLARSHIP_TEST]: '2',
  [SUNSTONE_TEST_VARIANT.SEAT_ALTERNATE_VERSION]: 'B',
  [SUNSTONE_TEST_VARIANT.SEAT_ALTERNATE_VERSION_D]: 'D',
  [SUNSTONE_TEST_VARIANT.SEAT_ALTERNATE_VERSION_E]: 'E',
  [SUNSTONE_TEST_VARIANT.SEAT_ALTERNATE_VERSION_F]: 'F',
};

export const TEST_STATE_SCHEMA_VERSION = 2;

export interface IPaymentPlan {
  id: number;
  type: 'MBG' | 'PAP' | 'REGULAR' | 'DECIDE_LATER';
  short_name: string;
  full_name: string;
  upfront_enrollment_amount: number;
  popular_text: string;
  popular_text_color: string;
  popular_background_color: string;
  fomo: string;
  salary_months_count: number;
  payment_year_count: number;
  seat_book_fee: number;
  maximum_cap: number;
}

export interface IPickerList {
  title: string;
  value: string | number;
}

export interface ICertification {
  id: number;
  certificate_path?: string;
  certification_name: string;
  certificate_date?: string;
  certificate_type?: string;
}

export interface IWorkExperience {
  id?: number;
  designation?: string;
  company?: string;
  role?: string;
  job_description?: string;
  start_date?: string;
  end_date?: string;
  currently_working?: '1' | '0' | boolean;
  salary?: number;
}

export const EventPrefix = {
  PROFILE: 'profile_',
};

export enum ID_PROOF_TYPE {
  aadhaar = 'Aadhaar',
  pan = 'PAN Card',
  passport = 'Passport',
  voterId = 'Voter ID',
  drivingLicence = 'Driving Licence',
}

export const IdProofTypesList = [
  ID_PROOF_TYPE.aadhaar,
  ID_PROOF_TYPE.pan,
  ID_PROOF_TYPE.passport,
  ID_PROOF_TYPE.voterId,
  ID_PROOF_TYPE.drivingLicence,
];

export const ID_PROOF_KEYS = {
  aadhaar_front: 'aadhaar_front',
  aadhaar_back: 'aadhaar_back',
  pan_front: 'pan_front',
  passport_front: 'passport_front',
  passport_back: 'passport_back',
  voter_id_back: 'voter_id_back',
  voter_id_front: 'voter_id_front',
  driving_licence_front: 'driving_licence_front',
  driving_licence_back: 'driving_licence_back',
};

export const ID_PROOF_LABELS = {
  [ID_PROOF_KEYS.aadhaar_front]: 'Aadhaar (Front)',
  [ID_PROOF_KEYS.aadhaar_back]: 'Aadhaar (Back)',
  [ID_PROOF_KEYS.pan_front]: 'PAN Card',
  [ID_PROOF_KEYS.passport_front]: 'Passport (Front)',
  [ID_PROOF_KEYS.passport_back]: 'Passport (Back)',
  [ID_PROOF_KEYS.voter_id_front]: 'Voter ID (Front)',
  [ID_PROOF_KEYS.voter_id_back]: 'Voter ID (Back)',
  [ID_PROOF_KEYS.driving_licence_front]: 'Driving Licence (Front)',
  [ID_PROOF_KEYS.driving_licence_back]: 'Driving Licence (Back)',
};

export const IdProofDocs: Record<ID_PROOF_TYPE, string[]> = {
  [ID_PROOF_TYPE.aadhaar]: [ID_PROOF_KEYS.aadhaar_front, ID_PROOF_KEYS.aadhaar_back],
  [ID_PROOF_TYPE.pan]: [ID_PROOF_KEYS.pan_front],
  [ID_PROOF_TYPE.passport]: [ID_PROOF_KEYS.passport_front, ID_PROOF_KEYS.passport_back],
  [ID_PROOF_TYPE.voterId]: [ID_PROOF_KEYS.voter_id_front, ID_PROOF_KEYS.voter_id_back],
  [ID_PROOF_TYPE.drivingLicence]: [ID_PROOF_KEYS.driving_licence_front, ID_PROOF_KEYS.driving_licence_back],
};

export interface IDomainFormSelect {
  uploadLabel?: string;
  files: IDocumentForm[];
  fields: IDocumentForm[];
  partialFilesAllowed?: boolean;
}

export interface IDomainForm {
  work_experience_flag?: string;
  id?: number;
  title: string;
  formName?: FormNames;
  conditional?: IDocumentForm;
  showAll?: boolean;
  currentSelection?: string;
  selectLabel?: string;
  select: Record<string, IDomainFormSelect>;
  partialFilled?: boolean;
}

export const CGPA_VALUES = [4, 5, 9, 10];
export const DEFAULT_CGPA_VALUE = 10;
export const TEST_START_KEY = 'autostart_test';
export const TEST_START_VALUE = '32640c57-b47a-4c10-b548-d4e85cd691bc';
export const TEST_ID_KEY = 'autostart_test_id';

export enum OfferLetterTypes {
  sunstone = 'sunstone',
  campus = 'campus',
}
export const PATTERNS = {
  number_only: /[^0-9]*/g,
  alphabets_included: /.*[a-zA-Z]+.*/,
  float_only: /[^0-9.]*/g,
};

export const MWEB_APP_LINK = 'https://sunstone.page.link/hedo';
export const APP_DEEP_LINK = 'https://sunstone.page.link/ytF6';
export const OPEN_APP_IF_INSTALLED_LINK = 'https://sunstone.page.link/PGC7';

export interface CampusDetails {
  id: number;
  campus_fullname: string;
  campus_city: string;
  campus_address?: string;
  campus_data: CampusData;
  image_url?: string;
  video_url: string;
}
export interface CampusData {
  fomo: string;
  amenities?: string[] | null;
  fomo_level: string;
  description: string;
  affiliation: string;
}

export const VIDEOASK_QUESTION_COUNT = 5;

export const CUSTOM_PLYR_CONTROLS = `<div class="scu__plyr__overlay" data-plyr="play" aria-label="Play">
  <button type="button" class="plyr__control">
    <i class="fa fa-regular fa-play"></i>
  </button>
  <h6>Continue Watching</h6>
</div>
<div class="plyr__controls">
  <div class="scu-plyr-timer">
    <span class="plyr__time plyr__time--current" aria-label="Current time">00:00</span>
    <span class="plyr__time plyr__time--duration" aria-label="Duration">00:00</span>
  </div>
<div class="scu__plyr__controls">
  <div class="plyr__progress">
    <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek"/>
  <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
  <span role="tooltip" class="plyr__tooltip">00:00</span>
</div>
<button type="button" class="plyr__control" data-plyr="fullscreen">
  <i class="fa-regular fa-expand icon--pressed" role="presentation"></i>
  <i class="fa-regular fa-expand icon--not-pressed" role="presentation"></i>
  <span class="label--pressed plyr__tooltip" role="tooltip">Exit fullscreen</span>
  <span class="label--not-pressed plyr__tooltip" role="tooltip">Enter fullscreen</span>
</button>
</div>
</div>`;

export const MODIFIED_TEST_QUESTION = {
  old: 79,
  new: 181,
};

export enum NativeActions {
  // action id for handling tasks on mobile native environments
  LOGIN = 'login',
  LOGOUT = 'logout',
}
export enum Urls {
  APP_LOGIN = '/admission',
}

export const firebaseBannerToken = {
  documentsSubmitted: `hub_banner_documents_submitted`,
  documentsRejected: `hub_banner_documents_rejected`,

  documentsVerified: `hub_banner_documents_verified`,
  applicationWaitingForOffer: `hub_banner_documents_verified`,

  applicationRejected: `hub_banner_application_rejected`,

  payRegistration: `hub_banner_pay_registration`,
  payWaiting: `hub_banner_pay_waiting`,
  payFailed: `hub_banner_pay_failed`,

  refundRequested: `hub_banner_refund_requested`,
  refundProcessed: `hub_banner_refund_processed`,
};
